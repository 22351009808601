const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://ktm8tt7uqj.execute-api.eu-west-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://qcjwt3k9dg.execute-api.eu-west-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://nycrtszsrd.execute-api.eu-west-1.amazonaws.com/prod'
  },
};

export default config;
